var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "help-center" },
    [
      _c("HeadNav"),
      _vm.help_list.length > 0
        ? _c(
            "yd-infinitescroll",
            { ref: "infinitescroll", attrs: { callback: _vm.getArticleList } },
            [
              _c(
                "div",
                {
                  staticClass: "help-list",
                  attrs: { slot: "list" },
                  slot: "list",
                },
                _vm._l(_vm.help_list, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "item",
                      on: {
                        click: function ($event) {
                          return _vm.toArticle(item.id)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
                  )
                }),
                0
              ),
              _c("span", { attrs: { slot: "doneTip" }, slot: "doneTip" }, [
                _vm._v("-没有更多了-"),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }