<template>
  <div class="help-center">
    <HeadNav/>
    <yd-infinitescroll v-if="help_list.length>0" :callback="getArticleList" ref="infinitescroll">
      <div class="help-list" slot="list">
        <div  class="item " v-for="item in help_list" :key="item.id" @click="toArticle(item.id)">
          {{item.title}}
        </div>
      </div>
      <!-- 数据全部加载完毕显示 -->
      <span slot="doneTip">-没有更多了-</span>
    </yd-infinitescroll>
  </div>
</template>

<script>
import HeadNav from "@/components/common/HeadNav";
import {getArticleList} from "@/services/my";

export default {
  name: "HelpCenter",
  data() {
    return {
      help_list: [],
      page: 1,
      listRows: 10,
      article_sort_id: '2',
    }
  },
  components: {
    HeadNav
  },
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    async getArticleList() {
      let params = {
        page: this.page,
        listRows: this.listRows,
        article_sort_id: this.article_sort_id
      };
      try {
        const res = await getArticleList(params);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.help_list = [...this.help_list, ...data.list];
          if (this.help_list.length === data.total) {
            /* 所有数据加载完毕 */
            this.$refs.infinitescroll.$emit('ydui.infinitescroll.loadedDone');
            return;
          }

          /* 单次请求数据完毕 */
          this.$refs.infinitescroll.$emit('ydui.infinitescroll.finishLoad');

          this.page++;
        }
      } catch (e) {

      }
    },
    toArticle(id){
      this.$router.push({
        name:'article',
        query:{
          id:id
        }
      })
    },
  },
  mounted() {
    this.getArticleList()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.help-center {
  .help-list {
    .item {
      height: 1rem;
      line-height: 1rem;
      background: @color-white;
      .sc(0.32rem, @color-dark-grey);
      padding: 0 0.24rem;
      background: white url(../../../assets/ic_arrow_right_gray@2x.png) no-repeat 97% 50%;
      background-size: 0.26rem 0.26rem;
      border-bottom: 0.01rem solid @color-row-line;
      &:last-child{
        border: 0 ;
      }
    }
  }
}
</style>
